import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
// Import your components
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";

import Course from "./pages/Course";
import Login from "./pages/Login";
import Reg from "./pages/Register";
import Footer from "./components/Footer";
import Profile from "./pages/Profile";
import { auth } from "./pages/firebase";
import Help from "./pages/Help";
import Coursepage from "./pages/Coursepage";
import Notfound from "./components/Notfound";

// Add loading spinner component
const LoadingSpinner = () => (
  <div className="flex justify-center items-center min-h-screen">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-400"></div>
  </div>
);

// Layout wrapper for consistent page transitions
const PageWrapper = ({ children }) => (
  <div className="page-transition animate-fadeIn min-h-screen bg-gray-50">
    {children}
  </div>
);

export default function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen for Firebase Authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set user state to the logged-in user or null
      setLoading(false); // Set loading to false once auth state is determined
    });

    return () => {
      unsubscribe(); // Unsubscribe when component unmounts
    };
  }, []);

  const isLoggedIn = () => {
    return user !== null;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="flex flex-col min-h-screen bg-gray-50">
        <div className="fixed flex-1 w-full z-20 shadow-md">
          <Navbar />
        </div>
        <div className="pt-16">
          <Routes>
            <Route path="/login" element={<PageWrapper><Login /></PageWrapper>} />
            <Route path="/register" element={<PageWrapper><Reg /></PageWrapper>} />
            <Route path="/help" element={<PageWrapper><Help /></PageWrapper>} />

            {isLoggedIn() && (
              <>
                <Route path="/" element={<PageWrapper><Course /></PageWrapper>} />
                <Route path="/courses/:courseName" element={<PageWrapper><Coursepage /></PageWrapper>} />
                <Route path="/profile" element={<PageWrapper><Profile /></PageWrapper>} />
              </>
            )}

            {isLoggedIn() ? (
              <Route path="*" element={<PageWrapper><Course /></PageWrapper>} />
            ) : (
              <Route path="*" element={<PageWrapper><Login /></PageWrapper>} />
            )}
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
