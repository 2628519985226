import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";

export default function CourseCard({ title, id, img, description, videos }) {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  
  // Default image if none is provided
  const defaultImg = "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80";

  useEffect(() => {
    const calculateProgress = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser;
        if (user && videos?.length > 0) {
          const savedCompletedVideos = localStorage.getItem(`completed_${id}_${user.email}`);
          if (savedCompletedVideos) {
            const completedVideos = JSON.parse(savedCompletedVideos);
            setProgress(Math.round((completedVideos.length / videos.length) * 100));
          }
        }
      } catch (error) {
        console.error("Error calculating progress:", error);
      } finally {
        setLoading(false);
      }
    };

    calculateProgress();
  }, [id, videos]);

  return (
    <div className="card group hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 bg-white rounded-xl overflow-hidden">
      <div className="relative overflow-hidden">
        <img
          src={img || defaultImg}
          alt={title}
          className="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute top-0 right-0 bg-blue-600 text-white font-semibold text-xs px-3 py-1 rounded-bl-lg">
          Enrolled
        </div>
        
        {videos && videos.length > 0 && (
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-3">
            <div className="flex items-center text-white text-xs">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
              {videos.length} {videos.length === 1 ? 'lesson' : 'lessons'}
            </div>
          </div>
        )}
      </div>
      
      <div className="p-6">
        <h3 className="font-bold text-xl text-gray-800 mb-2 line-clamp-2 group-hover:text-blue-600 transition-colors">
          {title || "Untitled Course"}
        </h3>
        
        {description && (
          <p className="text-gray-600 text-sm mb-4 line-clamp-2">
            {description}
          </p>
        )}

        {/* Progress bar */}
        {!loading && videos && videos.length > 0 && (
          <div className="mt-3 mb-4">
            <div className="flex justify-between text-xs text-gray-600 mb-1">
              <span>Progress</span>
              <span className="font-medium">{progress}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-1.5">
              <div 
                className={`h-1.5 rounded-full transition-all duration-500 ${
                  progress === 100 ? 'bg-green-500' : 'bg-blue-500'
                }`}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        )}
        
        <div className="flex items-center justify-between mt-4">
          <button
            className="flex items-center space-x-1 text-sm font-medium text-blue-600 hover:text-blue-800 transition-colors duration-200"
            onClick={() => navigate(`/courses/${id}`)}
          >
            <span>{progress === 0 ? "Start Learning" : "Continue Learning"}</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
          
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition-colors duration-200 flex items-center space-x-1 shadow-sm"
            onClick={() => navigate(`/courses/${id}`)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{progress === 0 ? "Start" : progress === 100 ? "Review" : "Resume"}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
