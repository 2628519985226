import React from "react";
import { Link } from "react-router-dom";

function Notfound() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center p-6 py-32">
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg overflow-hidden md:max-w-2xl">
        <div className="p-8">
          <div className="flex flex-col items-center text-center">
            <div className="relative mb-6">
              <div className="text-9xl font-extrabold text-blue-400 tracking-widest">404</div>
              <div className="absolute bottom-0 inset-x-0 flex items-center justify-center">
                <svg className="h-16 w-16 text-blue-200" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19.9 12.66a1 1 0 0 1 0 1.32l-8 6a1 1 0 0 1-1.55-.83V5.5a1 1 0 0 1 1.55-.83l8 6a1 1 0 0 1 0 1.32Z" opacity={0.2}></path>
                  <path d="M12.35 4.67a1 1 0 0 0-1 0 1 1 0 0 0-.5.86v12.94a1 1 0 0 0 .5.86 1 1 0 0 0 .5.13 1 1 0 0 0 .55-.17l8-6a1 1 0 0 0 0-1.66ZM13 16.15V7.85L17.83 12Z"></path>
                  <path d="M8 5H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2H5V7h3a1 1 0 0 0 0-2Z"></path>
                </svg>
              </div>
            </div>
            
            <h1 className="mt-4 text-2xl font-bold text-gray-800">Oops! Page Not Found</h1>
            
            <p className="mt-2 text-gray-600">
              The page you're looking for doesn't exist or has been moved.
            </p>
            
            <div className="mt-8 space-y-4">
              <Link
                to="/"
                className="btn-primary w-full flex items-center justify-center space-x-2"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
                <span>Return Home</span>
              </Link>
              
              <Link
                to="/help"
                className="btn-secondary w-full flex items-center justify-center space-x-2"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Contact Support</span>
              </Link>
            </div>
            
            <div className="mt-8 text-center">
              <p className="text-sm text-gray-500">
                Lost? Try searching for what you need or explore our popular courses below.
              </p>
            </div>
            
            <div className="mt-6 grid grid-cols-2 gap-4 text-center text-sm">
              <Link 
                to="/"
                className="bg-gray-50 hover:bg-gray-100 rounded-lg p-4 transition-colors duration-200"
              >
                <span className="block font-medium text-gray-800">Web Development</span>
                <span className="text-xs text-gray-500">Frontend & Backend</span>
              </Link>
              <Link 
                to="/"
                className="bg-gray-50 hover:bg-gray-100 rounded-lg p-4 transition-colors duration-200"
              >
                <span className="block font-medium text-gray-800">Data Science</span>
                <span className="text-xs text-gray-500">ML & Analytics</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notfound;
