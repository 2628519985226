import React, { useState } from "react";

function ContactForm() {
  // Define state variables for form inputs
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulate form submission
    setSubmitted(true);
    // In a real app, you would send data to an API
    console.log("Form submitted:", { email, subject, message });
  };

  const faqData = [
    {
      question: "How do I enroll in a course?",
      answer: "To enroll in a course, browse our course catalog, select the course you're interested in, and click the 'Enroll' button. You may need to create an account or log in if you haven't already."
    },
    {
      question: "Can I access my courses on mobile devices?",
      answer: "Yes, our platform is fully responsive and works on mobile devices. You can access your courses through any modern web browser on smartphones and tablets."
    },
    {
      question: "How long do I have access to a course after enrolling?",
      answer: "Once enrolled, you have lifetime access to the course content, allowing you to learn at your own pace and revisit materials whenever needed."
    },
    {
      question: "Do you offer certificates upon course completion?",
      answer: "Yes, we provide certificates of completion for most courses. Once you've completed all required lessons and assignments, you can download your personalized certificate."
    },
  ];

  return (
    <section className="bg-gray-50 py-20">
      <div className="container mx-auto px-4">
        {/* Help Center Header */}
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">How can we help you?</h1>
          <p className="text-lg text-gray-600">
            Find answers to common questions or contact our support team for assistance
          </p>
        </div>
        
        <div className="max-w-6xl mx-auto">

                    {/* Contact Form */}
                    <div className="bg-white rounded-xl shadow-md overflow-hidden mb-16">
            <div className="p-6 border-b border-gray-200">
              <h2 className="text-2xl font-bold text-gray-800">Contact Support</h2>
              <p className="text-gray-600 mt-1">
                Got a specific question? Our team is here to help you.
              </p>
            </div>
            <div className="p-6">
              {submitted ? (
                <div className="text-center py-8">
                  <div className="inline-block p-4 bg-green-100 rounded-full mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-bold text-gray-800 mb-2">Message Sent Successfully!</h3>
                  <p className="text-gray-600 mb-6">
                    Thank you for reaching out. Our team will get back to you shortly.
                  </p>
                  <button
                    onClick={() => {
                      setSubmitted(false);
                      setEmail("");
                      setSubject("");
                      setMessage("");
                    }}
                    className="btn-secondary"
                  >
                    Send Another Message
                  </button>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-input"
                      placeholder="your@email.com"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      className="form-input"
                      placeholder="How can we help you?"
                      required
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows="5"
                      className="form-input"
                      placeholder="Please provide as much detail as possible..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="flex items-center">
                    <button type="submit" className="btn-primary">
                      Send Message
                    </button>
                    <p className="text-xs text-gray-500 ml-4">
                      We typically respond within 24-48 hours.
                    </p>
                  </div>
                </form>
              )}
            </div>
          </div>
          {/* FAQ Section */}
          <div className="bg-white rounded-xl shadow-md overflow-hidden ">
            <div className="p-6 border-b border-gray-200">
              <h2 className="text-2xl font-bold text-gray-800">Frequently Asked Questions</h2>
            </div>
            <div className="divide-y divide-gray-200">
              {faqData.map((faq, index) => (
                <div key={index} className="p-6">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>


        </div>
      </div>
    </section>
  );
}

export default ContactForm;
