import React, { useState, useEffect } from "react";
import { auth, db } from "./firebase";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("profile");
  
  useEffect(() => {
    const fetchUserData = async (email) => {
      setLoading(true);
      try {
        const userDoc = await db.collection("user").doc(email).get();
        if (userDoc.exists) {
          const data = userDoc.data();
          setUserData(data);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        toast.error("Failed to load profile data");
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        fetchUserData(authUser.email);
      } else {
        setUser(null);
        setUserData({});
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handlePasswordReset = async () => {
    if (!user?.email) return;
    
    try {
      await auth.sendPasswordResetEmail(user.email);
      toast.info("Password reset email sent. Check your inbox.");
    } catch (error) {
      toast.error("Failed to send password reset email");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-400"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="container mx-auto  py-24">
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden">
          <div className="p-8 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-gray-400 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Sign In Required</h2>
            <p className="text-gray-600 mb-6">
              Please sign in to view and manage your profile information.
            </p>
            <Link to="/login" className="btn-primary">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const userInitial = userData.name ? userData.name.charAt(0).toUpperCase() : user.email.charAt(0).toUpperCase();

  return (
    <div className="container mx-auto  py-24">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          {/* Profile Header */}
          <div className="bg-gradient-to-r from-blue-400 to-blue-500 px-6 py-16 relative">
            <div className="flex flex-col md:flex-row items-center justify-between relative z-10">
              <div className="flex items-center mb-4 md:mb-0">
                <div className="w-28 h-28 rounded-full bg-white p-1 shadow-lg">
                  {user.photoURL ? (
                    <img
                      src={user.photoURL}
                      alt={userData.name || "User"}
                      className="w-full h-full rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full rounded-full bg-gray-100 flex items-center justify-center">
                      <span className="text-4xl font-bold text-blue-500">{userInitial}</span>
                    </div>
                  )}
                </div>
                <div className="ml-4 text-white">
                  <h1 className="text-2xl font-bold">{userData.name || "User"}</h1>
                  <p className="opacity-80">{user.email}</p>
                  {userData.location && (
                    <p className="flex items-center opacity-80 text-sm mt-1">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      {userData.location}
                    </p>
                  )}
                </div>
              </div>

            </div>
            
            {/* Abstract shapes in the background */}
            <div className="absolute inset-0 opacity-20">
              <div className="absolute right-0 bottom-0 w-40 h-40 bg-white rounded-full transform translate-x-1/3 translate-y-1/3"></div>
              <div className="absolute left-0 top-0 w-20 h-20 bg-white rounded-full transform -translate-x-1/2 -translate-y-1/2"></div>
            </div>
          </div>
          
          {/* Profile Tabs */}
          <div className="border-b border-gray-200 px-4">
            <nav className="flex">
              <button
                className={` py-4 font-medium text-sm ${
                  activeTab === "profile"
                    ? "text-blue-600 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab("profile")}
              >
                Profile
              </button>
              <button
                className={`px-4 py-4 font-medium text-sm ${
                  activeTab === "courses"
                    ? "text-blue-600 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab("courses")}
              >
                My Courses
              </button>
              <button
                className={` py-4 font-medium text-sm ${
                  activeTab === "settings"
                    ? "text-blue-600 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab("settings")}
              >
                Settings
              </button>
            </nav>
          </div>
          
          {/* Profile Content */}
          <div className="p-6">
            {activeTab === "profile" && (
              <div className="space-y-6">
                <div className=" border-gray-200 pt-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Account Information</h3>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Email Address</dt>
                      <dd className="mt-1 text-gray-900">{user.email}</dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Member Since</dt>
                      <dd className="mt-1 text-gray-900">
                        {user.metadata?.creationTime 
                          ? new Date(user.metadata.creationTime).toLocaleDateString() 
                          : "Unknown"}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            )}
            
            {activeTab === "courses" && (
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">My Enrolled Courses</h3>
                {(userData.courses?.length > 0) ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {userData.courses.map((courseId) => (
                      <div key={courseId} className="card">
                        <div className="p-6">
                          <h4 className="font-bold text-lg mb-1">{courseId}</h4>
                          <p className="text-sm text-gray-500 mb-3">Course ID: {courseId}</p>
                          <Link to={`/courses/${courseId}`} className="btn-primary text-sm">
                            Continue Learning
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-12 bg-gray-50 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                    </svg>
                    <p className="text-gray-500 mb-6">You haven't enrolled in any courses yet.</p>
                    <Link to="/" className="btn-primary">
                      Browse Courses
                    </Link>
                  </div>
                )}
              </div>
            )}
            
            {activeTab === "settings" && (
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Account Settings</h3>
                
                <div className="space-y-6">
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h4 className="font-medium text-gray-900 mb-2">Change Password</h4>
                    <p className="text-sm text-gray-600 mb-4">
                      Receive an email to reset your password.
                    </p>
                    <button 
                      onClick={handlePasswordReset}
                      className="btn-primary"
                    >
                      Reset Password
                    </button>
                  </div>
                  
             
                  
                  <div className="mt-4 pt-4 border-t border-gray-200">
                    <button 
                      onClick={() => auth.signOut()}
                      className="flex items-center text-red-600 hover:text-red-800 font-medium"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                      Sign Out
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
};

export default Profile;
