import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// This component automatically scrolls the window to the top whenever
// the route location changes (when user navigates to a different page)
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to top with smooth animation
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null; // This component doesn't render anything
}

export default ScrollToTop;
