import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../pages/firebase";
import logo from "../pages/logo.jpg";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [showLogout, setShowLogout] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      unsubscribe();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const isActive = (path) => {
    return location.pathname === path ? 
      "text-blue-500 font-bold" : 
      "text-gray-700 hover:text-blue-500";
  };
  
  const handleLogout = () => {
    auth.signOut();
    setShowLogout(false);
  };

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <header className={`bg-white text-gray-800 transition-all duration-300 ${scrolled ? 'shadow-md' : ''}`}>
      <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <Link className="flex items-center" to="/" onClick={handleLogoClick}>
          <img
            className="h-10 w-auto"
            src={logo}
            alt="Ava Intern Logo"
          />
        </Link>

        <nav className="hidden md:flex items-center space-x-6">
          {user && (
            <>
              <Link
                className={`transition-colors duration-200 text-sm font-medium rounded-md py-2 px-3 ${isActive('/')}`}
                to="/"
              >
                My Courses
              </Link>
              <Link
                className={`transition-colors duration-200 text-sm font-medium rounded-md py-2 px-3 ${isActive('/profile')}`}
                to="/profile"
              >
                My Profile
              </Link>
            </>
          )}
          <Link
            className={`transition-colors duration-200 text-sm font-medium rounded-md py-2 px-3 ${isActive('/help')}`}
            to="/help"
          >
            Help
          </Link>
        </nav>

        <div className="flex items-center gap-4">
          {user ? (
            <div className="relative">
              <button
                onClick={() => setShowLogout(!showLogout)}
                className="flex items-center space-x-2 rounded-full bg-gradient-to-r from-blue-50 to-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 hover:shadow-md transition-all duration-200 border border-gray-200"
                aria-expanded={showLogout}
                aria-haspopup="true"
              >
                <span className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-blue-600 flex items-center justify-center text-white font-bold shadow-sm">
                  {user.email.charAt(0).toUpperCase()}
                </span>
                <span className="hidden sm:inline font-semibold">{user.email.split('@')[0]}</span>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className={`h-4 w-4 transition-transform duration-200 ${showLogout ? 'rotate-180' : ''}`} 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>

              {showLogout && (
                <>
                  <div 
                    className="fixed inset-0 z-40 bg-black bg-opacity-10" 
                    onClick={() => setShowLogout(false)}
                  ></div>
                  <div 
                    className="absolute right-0 mt-2 w-56 rounded-lg shadow-lg z-50 transform transition-all duration-200 origin-top-right"
                    style={{ animation: 'dropdown-fade-in 0.2s ease-out' }}
                  >
                    <div className="rounded-lg bg-white ring-1 ring-black ring-opacity-5 py-1 overflow-hidden">
                      <div className="px-4 py-3 border-b border-gray-100">
                        <p className="text-sm text-gray-500">Signed in as</p>
                        <p className="text-sm font-medium text-gray-900 truncate">{user.email}</p>
                      </div>
                      <Link
                        to="/profile"
                        onClick={() => setShowLogout(false)}
                        className="flex items-center px-4 py-2.5 text-sm text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-150"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        View Profile
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="flex items-center w-full text-left px-4 py-2.5 text-sm text-red-600 hover:bg-red-50 transition-colors duration-150"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Logout
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <Link
                className="rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 transition-all duration-200"
                to="/login"
              >
                Log in
              </Link>
              <Link
                className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-500 transition-all duration-200 shadow-sm"
                to="/register"
              >
                Register
              </Link>
            </div>
          )}

          <button
            className="inline-flex items-center md:hidden rounded-md bg-gray-100 p-2 text-gray-600 hover:bg-gray-200 transition-all duration-200"
            onClick={handleMenuToggle}
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Menu</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden fixed inset-0 z-40 bg-black bg-opacity-25" onClick={closeMenu}>
          <div className="fixed inset-y-0 right-0 w-full max-w-sm bg-white shadow-xl p-6" onClick={e => e.stopPropagation()}>
            <div className="flex items-center justify-between mb-6">
              <Link to="/" className="flex items-center" onClick={closeMenu}>
                <img
                  className="h-8 w-auto"
                  src="https://d8it4huxumps7.cloudfront.net/uploads/images/opportunity/gallery/656d7371dc586_ava_logo.jpg?d=600x600"
                  alt="Ava Intern Logo"
                />
                <span className="ml-2 text-xl font-bold text-gray-800">Ava Intern</span>
              </Link>
              <button
                className="rounded-md p-2 text-gray-600 hover:bg-gray-100"
                onClick={closeMenu}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <nav className="flex flex-col space-y-4">
              {user && (
                <>
                  <Link
                    to="/"
                    onClick={closeMenu}
                    className={`py-2 px-3 text-base font-medium rounded-md ${isActive('/')}`}
                  >
                    My Courses
                  </Link>
                  <Link
                    to="/profile"
                    onClick={closeMenu}
                    className={`py-2 px-3 text-base font-medium rounded-md ${isActive('/profile')}`}
                  >
                    My Profile
                  </Link>
                </>
              )}
              <Link
                to="/help"
                onClick={closeMenu}
                className={`py-2 px-3 text-base font-medium rounded-md ${isActive('/help')}`}
              >
                Help
              </Link>
              
              {user ? (
                <button
                  onClick={handleLogout}
                  className="mt-4 w-full py-3 px-4 rounded-md bg-red-100 text-red-600 font-medium hover:bg-red-200 transition-colors duration-200"
                >
                  Logout
                </button>
              ) : (
                <div className="mt-4 space-y-3">
                  <Link
                    to="/login"
                    onClick={closeMenu}
                    className="block w-full py-3 px-4 rounded-md bg-gray-100 text-center text-gray-800 font-medium hover:bg-gray-200 transition-colors duration-200"
                  >
                    Log in
                  </Link>
                  <Link
                    to="/register"
                    onClick={closeMenu}
                    className="block w-full py-3 px-4 rounded-md bg-blue-400 text-center text-gray-900 font-medium hover:bg-blue-500 transition-colors duration-200"
                  >
                    Register
                  </Link>
                </div>
              )}
            </nav>
          </div>
        </div>
      )}
    </header>
  );
}
