import React, { useState, useEffect } from "react";
import CourseCard from "./CourseCard";
import { auth, db } from "./firebase";
import { Link } from "react-router-dom";

export default function Course() {
  const [courses, setCourses] = useState([]);
  const [userUID, setUserUID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("All");

  useEffect(() => {
    // Listen for changes in the authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserUID(user.email);
      } else {
        setUserUID(null);
        setCourses([]);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserCourses = async () => {
      if (userUID) {
        setLoading(true);
        try {
          const userDoc = await db.collection("user").doc(userUID).get();

          if (!userDoc.exists || !userDoc.data()) {
            console.warn("User document not found or has no data.");
            setLoading(false);
            return;
          }

          const userCourses = userDoc.data().courses || [];

          // Then, fetch the detailed information for each course
          const courseDocs = await Promise.all(
            userCourses.map((courseId) =>
              db.collection("courses").doc(courseId).get()
            )
          );

          // Transform the course documents to their data
          const fetchedCourses = courseDocs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          setCourses(fetchedCourses);
        } catch (error) {
          console.error("Error fetching courses:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserCourses();
  }, [userUID]); // Re-run this effect when the userUID changes
  
  // Get all categories for filtering
  const categories = courses.length > 0 
    ? ["All", ...new Set(courses.map(course => course.category).filter(Boolean))] 
    : ["All"];
  
  // Filter courses by search query and category
  const filteredCourses = courses.filter(course => {
    // Fix for TypeError: Check if title exists before calling toLowerCase()
    const matchesQuery = (course.title ? course.title.toLowerCase().includes(searchQuery.toLowerCase()) : false) || 
                         (course.description && course.description.toLowerCase().includes(searchQuery.toLowerCase()));
    const matchesCategory = categoryFilter === "All" || course.category === categoryFilter;
    return matchesQuery && matchesCategory;
  });

  return (
    <section className="bg-gradient-to-b from-gray-50 to-gray-100 py-16">
      <div className="container mx-auto pt-2">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            My Learning Journey
          </h2>
          <p className="text-lg text-gray-600">
            Continue your education with our expert-led courses designed to boost your skills
          </p>
        </div>
        
        {loading ? (
          <div className="flex justify-center py-20">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : userUID ? (
          courses.length === 0 ? (
            <div className="bg-white rounded-xl shadow-md p-12 max-w-2xl mx-auto text-center">
              <div className="mb-8">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-blue-400 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-3">No Courses Enrolled</h3>
              <p className="text-gray-600 mb-8 max-w-md mx-auto">
                You haven't enrolled in any courses yet. Browse our catalog to find courses that match your interests and advance your skills.
              </p>
              <Link to="/catalog" className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2.5 px-5 rounded-md transition-colors duration-200 inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
                Browse Courses
              </Link>
            </div>
          ) : (
            <>
              <div className="mb-10 flex flex-col md:flex-row justify-between gap-4">
                {/* Search box */}
                <div className="relative md:w-64">
                  <input
                    type="text"
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-300 focus:border-blue-400 transition-all"
                    placeholder="Search your courses..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </div>
                </div>
                
                {/* Category filter */}
                {categories.length > 1 && (
                  <div className="flex flex-wrap gap-2">
                    {categories.map(category => (
                      <button
                        key={category}
                        onClick={() => setCategoryFilter(category)}
                        className={`py-2  rounded-md text-sm font-medium transition-colors ${
                          categoryFilter === category
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        }`}
                      >
                        {category}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              
              {filteredCourses.length === 0 ? (
                <div className="text-center py-10 bg-white rounded-lg shadow-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-gray-400 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <h3 className="text-xl font-medium text-gray-800 mb-2">No courses found</h3>
                  <p className="text-gray-600">
                    No courses match your search criteria. Try adjusting your filters.
                  </p>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {filteredCourses.map((course) => (
                      <CourseCard key={course.id} {...course} />
                    ))}
                  </div>
                  
                </>
              )}
            </>
          )
        ) : (
          <div className="bg-white rounded-xl shadow-md p-10 max-w-2xl mx-auto text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-blue-400 mx-auto mb-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
            <h3 className="text-2xl font-semibold text-gray-800 mb-3">Sign In Required</h3>
            <p className="text-gray-600 mb-6">
              Please sign in to view your enrolled courses and continue your learning journey.
            </p>
            <div className="flex justify-center gap-4">
              <Link to="/login" className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2.5 px-5 rounded-md transition-colors duration-200">
                Sign In
              </Link>
              <Link to="/register" className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2.5 px-5 rounded-md transition-colors duration-200">
                Create Account
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
