import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "./firebase";

const MainSection = () => {
  const { courseName } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("content"); // content, notes
  const [notes, setNotes] = useState("");
  const [completedVideos, setCompletedVideos] = useState([]);
  const [saveStatus, setSaveStatus] = useState("");

  useEffect(() => {
    const fetchUserAndCourseData = async () => {
      try {
        setLoading(true);
        const user = auth.currentUser;

        if (!user) {
          navigate("/");
          return;
        }

        const userDoc = await db.collection("user").doc(user.email).get();
        const userCourses = userDoc.data().courses;

        if (!userCourses.includes(courseName)) {
          navigate("/");
          return;
        }

        const courseDoc = await db.collection("courses").doc(courseName).get();

        if (!courseDoc.exists) {
          navigate("/");
          return;
        }

        setCourse(courseDoc.data());

        const courseData = courseDoc.data();
        if (courseData.videos && courseData.videos.length) {
          setCurrentVideo(courseData.videos[0]);
        }

        // Load saved notes from localStorage
        const savedNotes = localStorage.getItem(`notes_${courseName}_${user.email}`);
        if (savedNotes) {
          setNotes(savedNotes);
        }

        // Load completed videos from localStorage
        const savedCompletedVideos = localStorage.getItem(`completed_${courseName}_${user.email}`);
        if (savedCompletedVideos) {
          setCompletedVideos(JSON.parse(savedCompletedVideos));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndCourseData();
  }, [courseName, navigate]);

  useEffect(() => {
    // Update notes when changing videos
    if (currentVideo && auth.currentUser) {
      const savedNotes = localStorage.getItem(`notes_${courseName}_${currentVideo.title}_${auth.currentUser.email}`);
      setNotes(savedNotes || "");
    }
  }, [currentVideo, courseName]);

  const handleSaveNotes = () => {
    if (auth.currentUser && currentVideo) {
      localStorage.setItem(`notes_${courseName}_${currentVideo.title}_${auth.currentUser.email}`, notes);
      setSaveStatus("Notes saved successfully!");
      setTimeout(() => setSaveStatus(""), 3000);
    }
  };

  const markVideoAsCompleted = (videoTitle) => {
    if (auth.currentUser) {
      let updatedCompletedVideos;
      if (completedVideos.includes(videoTitle)) {
        updatedCompletedVideos = completedVideos.filter(title => title !== videoTitle);
      } else {
        updatedCompletedVideos = [...completedVideos, videoTitle];
      }
      
      setCompletedVideos(updatedCompletedVideos);
      localStorage.setItem(
        `completed_${courseName}_${auth.currentUser.email}`,
        JSON.stringify(updatedCompletedVideos)
      );
    }
  };

  const calculateProgress = () => {
    if (course && course.videos && course.videos.length > 0) {
      return Math.round((completedVideos.length / course.videos.length) * 100);
    }
    return 0;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-400"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen pt-20 pb-20">
      <div className="container mx-auto ">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Main content area */}
          <div className="lg:w-2/3 space-y-6">
            {/* Video player */}
            <div className="bg-white rounded-xl overflow-hidden shadow-lg">
              <div className="relative bg-black aspect-video">
                {currentVideo && (
                  <video
                    className="w-full h-full"
                    controls
                    controlsList="nodownload"
                    autoPlay
                    key={currentVideo.url}
                    onEnded={() => !completedVideos.includes(currentVideo.title) && markVideoAsCompleted(currentVideo.title)}
                  >
                    <source src={currentVideo.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
              
              <div className="p-6">
                <div className="flex justify-between items-center mb-2">
                  <h1 className="text-2xl font-bold text-gray-900">
                    {currentVideo && currentVideo.title}
                  </h1>
                  {currentVideo && completedVideos.includes(currentVideo.title) ? (
                    <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Completed
                    </span>
                  ) : (
                    <button 
                      onClick={() => currentVideo && markVideoAsCompleted(currentVideo.title)}
                      className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded hover:bg-blue-200 transition-colors"
                    >
                      Mark as complete
                    </button>
                  )}
                </div>
                <div className="flex items-center text-sm text-gray-500 mb-4">
               
                </div>
              </div>
              
              {/* Tabs */}
              <div className="border-t border-gray-200">
                <div className="flex">
                  <button 
                    className={`flex-1 py-4 px-6 text-center font-medium text-sm ${activeTab === 'content' ? 'text-blue-600 border-b-2 border-blue-500' : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setActiveTab("content")}
                  >
                    Content
                  </button>
                  <button 
                    className={`flex-1 py-4 px-6 text-center font-medium text-sm ${activeTab === 'notes' ? 'text-blue-600 border-b-2 border-blue-500' : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setActiveTab("notes")}
                  >
                    Notes
                  </button>
                </div>
                
                <div className="p-6">
                  {activeTab === "content" && (
                    <div className="prose max-w-none">
                      <h3 className="text-lg font-semibold mb-4">About this lesson</h3>
                      <p className="text-gray-700 leading-relaxed">{course && course.description}</p>
                      {currentVideo && currentVideo.description && (
                        <div className="mt-6 bg-gray-50 p-4 rounded-lg border border-gray-100">
                          <h4 className="text-md font-semibold mb-2">Video Description</h4>
                          <p className="text-gray-700">{currentVideo.description}</p>
                        </div>
                      )}
                    </div>
                  )}
                  
                  {activeTab === "notes" && (
                    <div>
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">Your Notes</h3>
                        <div className="flex items-center">
                          {saveStatus && (
                            <span className="text-green-600 text-sm mr-3">{saveStatus}</span>
                          )}
                          <button 
                            className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2  rounded-md transition-colors duration-200 flex items-center p-2"
                            onClick={handleSaveNotes}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                            </svg>
                            Save Notes
                          </button>
                        </div>
                      </div>
                      <textarea 
                        className="w-full h-56 p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-blue-400 font-medium text-gray-700"
                        placeholder="Take notes for this lesson here..."
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      ></textarea>
                      <p className="text-sm text-gray-500 mt-2">Notes are saved locally in your browser and tied to this specific video.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          {/* Sidebar - Course Content */}
          <div className="lg:w-1/3">
            <div className="bg-white rounded-xl overflow-hidden shadow-lg sticky top-24">
              <div className="p-6 border-b border-gray-200">
                <h2 className="text-xl font-bold text-gray-800">Course Content</h2>
                <p className="text-sm text-gray-500 mt-1">
                  {course && course.videos ? `${course.videos.length} lessons` : "0 lessons"}
                  {completedVideos.length > 0 && ` • ${completedVideos.length} completed`}
                </p>
              </div>
              
              <div className="overflow-y-auto max-h-[500px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                {course && course.videos && course.videos.map((video, index) => (
                  <div 
                    key={index}
                    className={`border-b last:border-0 border-gray-100 ${
                      currentVideo && currentVideo.title === video.title 
                      ? "bg-blue-50" 
                      : ""
                    }`}
                  >
                    <button
                      className="w-full text-left p-4 hover:bg-gray-50 transition-colors duration-200 flex items-start"
                      onClick={() => setCurrentVideo(video)}
                    >
                      <div className="flex items-center mr-3">
                        {completedVideos.includes(video.title) ? (
                          <span className="flex items-center justify-center rounded-full min-w-8 h-8 bg-green-500 p-2 text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </span>
                        ) : (
                          <span className={`flex items-center justify-center rounded-full min-w-8 p-3 h-8 ${
                            currentVideo && currentVideo.title === video.title 
                            ? "bg-blue-400 text-black" 
                            : "bg-gray-200 text-gray-700"
                          }`}>
                            {index + 1}
                          </span>
                        )}
                      </div>
                      <div className="flex-1">
                        <h3 className={`font-medium ${
                          currentVideo && currentVideo.title === video.title
                          ? "text-blue-700"
                          : completedVideos.includes(video.title)
                            ? "text-gray-800"
                            : "text-gray-700"
                        }`}>
                          {video.title}
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">
                           {video.type || "Video"}
                        </p>
                      </div>
                      
                      {currentVideo && currentVideo.title === video.title ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 text-blue-500 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      ) : null}
                    </button>
                  </div>
                ))}
              </div>
              
              {/* Course Progress */}
              <div className="p-6 border-t border-gray-200">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm font-medium text-gray-700">Your Progress</span>
                  <span className="text-sm font-medium text-blue-600">
                    {`${calculateProgress()}%`}
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className="bg-blue-400 h-2.5 rounded-full transition-all duration-500"
                    style={{ width: `${calculateProgress()}%` }}
                  ></div>
                </div>
                <div className="mt-4 text-center">
                  {calculateProgress() === 100 ? (
                    <span className="inline-flex items-center  py-2 bg-green-100 text-green-800 rounded-full text-sm font-medium">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Course Completed!
                    </span>
                  ) : course && course.videos && (
                    <span className="text-sm text-gray-600">
                      {completedVideos.length} of {course.videos.length} lessons completed
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
